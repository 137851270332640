import React from 'react';
import Logo from '../components/assets/logo.svg';
import DirectorPicture from '../components/assets/Abiodun.jpeg';

function CompanyHomePage() {
  return (
    <div className="flex flex-wrap">
    <div className=' flex w-500 md:w-500 lg:w-1/2 '>
        <img src= {Logo} alt="logo" />
    </div>
    <div className='w-500 md:w-500 lg:w-1/2 '>
      <div className="">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="max-w-xl mx-auto">
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold tracking-tight text-sky-800">Meet Our Director</h2>
            <p className="mt-4 md:mt-8 text-base md:text-lg lg:text-xl text-gray-500 leading-relaxed">
            My love for complex problem-solving and design puts me in a rare and valuable category of thinkers. I enjoy tackling challenging problems and finding innovative solutions to them. My mind is naturally wired to analyze, deconstruct, and reimagine systems, processes, and ideas in a way that brings clarity and understanding to even the most intricate and difficult situations.
            <br /><br />
            I have created this company to provide user-friendly webservices that is capable of helping people or organizations in what they do.

            </p>
            <div className="mt-6 flex items-center md:mt-12">
              <div className="flex-shrink-0">
                <img className="h-40 w-40 md:h-40 md:w-40 rounded-full" src={DirectorPicture} alt="Picture of the Director" />
              </div>
              <div className="ml-4 md:ml-8">
                <h3 className="text-lg md:text-xl lg:text-2xl font-medium text-gray-900">Abiodun Adenugba</h3>
                <p className="text-sm md:text-base lg:text-lg font-medium text-gray-500">Director</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  );
}

export default CompanyHomePage;
