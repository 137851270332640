
import NavBar from '../src/components/Navbar'
import logo from '../src/components/assets/FullLogo.svg'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import CompanyHomePage from './pages/CompanyHomePage';

function App() {
  return (
    <Router>
      <CompanyHomePage />
    </Router>

      
      

    
  );
}

export default App;
